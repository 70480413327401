import React from 'react';

import { useFlyout } from '../../hooks';

export type FlyoutCloseProps = {
  title: string;
};

export const FlyoutClose: React.FC<FlyoutCloseProps> = ({ title }) => {
  const { hideFlyout } = useFlyout();

  return (
    <button
      className="MainNavFlyout--close"
      type="button"
      onClick={() => hideFlyout()}
    >
      <span className="visuallyhidden">{title}</span>
      <span className="MainNavFlyout--closeIcon">
        <span className="MainNavFlyout--closeIcon--line" />
        <span className="MainNavFlyout--closeIcon--line" />
      </span>
    </button>
  );
};
