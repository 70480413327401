import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconKey, IconSvg } from '../0-atoms';

export type EmployerBrandingProps = {};

export const EmployerBranding: React.FC<EmployerBrandingProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="EmployerBranding">
      <div className="EmployerBranding--wrapper">
        <div className="EmployerBranding--content">
          <div className="EmployerBranding--title h2">
            {t('employer_branding.title')}
          </div>
          <div className="EmployerBranding--description">
            {t('employer_branding.description')}
          </div>
          <div className="EmployerBranding--ctas">
            <a
              className="EmployerBranding--cta"
              href={t('employer_branding.cta_1.url')}
              rel="noreferrer"
            >
              {t('employer_branding.cta_1.text')}
              <IconSvg
                position={'inline'}
                wrapClass={'EmployerBranding--cta--icon'}
                icon={`ico-arrow-right` as IconKey}
                transition
              />
            </a>
            <a
              className="EmployerBranding--cta"
              href={t('employer_branding.cta_2.url')}
              target="_blank"
              rel="noreferrer"
            >
              {t('employer_branding.cta_2.text')}
              <IconSvg
                position={'inline'}
                wrapClass={'EmployerBranding--cta--icon'}
                icon={`ico-arrow-right` as IconKey}
                transition
              />
            </a>
          </div>
        </div>
        <div className="EmployerBranding--badges">
          <div className="EmployerBranding--badge EmployerBranding--badge-one">
            <img
              src={t('employer_branding.badges.one.img')}
              alt={t('employer_branding.badges.one.alt')}
            />
          </div>
          <div className="EmployerBranding--badge EmployerBranding--badge-two">
            <img
              src={t('employer_branding.badges.two.img')}
              alt={t('employer_branding.badges.two.alt')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
