import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';

export type NewsletterSignupButtonProps = {
  url: string;
  label: string;
};

export const NewsletterSignupButton: React.FC<NewsletterSignupButtonProps> = ({
  url,
  label,
}) => {
  const { Link } = useFrameworkDependencies();

  return (
    <Link className="Button cta-white" type="button" to={url}>
      <span className="Button--text">{label}</span>
      <span className="LinkList--link--icon">
        <i className="ico ico-bolt-right-white" />
      </span>
    </Link>
  );
};
