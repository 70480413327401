import classNames from 'classnames';
import React, { useState } from 'react';

import { Locale } from '../../../i18n';
import { useFrameworkDependencies } from '../../dependencies';

export const LanguageSwitcher: React.FC = () => {
  const { Link, useLocalization, usePageTranslations } =
    useFrameworkDependencies();
  const { locale, locales } = useLocalization();
  const translationsMap = usePageTranslations();
  const translations = Object.fromEntries(
    translationsMap as Map<Locale, string>,
  );
  const [isVisible, setIsVisible] = useState(false);
  return Object.keys(translations).length ? (
    <li>
      <div className="Flyout">
        <button
          aria-haspopup="true"
          className="Flyout--trigger js-Flyout--trigger MetaNav--link LanguageSelectorFlyout--link"
          type="button"
          accessKey="5"
          onClick={() => setIsVisible(true)}
        >
          {locale.toUpperCase()}
        </button>
        <div
          className={classNames('Flyout--overlay', {
            'is-visible': isVisible,
          })}
          onClick={() => setIsVisible(false)}
        />
        <div
          className={classNames(
            'Flyout--container align-right LanguageSelectorFlyout',
            {
              'is-visible': isVisible,
            },
          )}
        >
          <div className="Flyout--container--arrow" />
          <div className="Flyout--content">
            <ul className="Flyout--list">
              {Object.values(locales).map(({ code, name }) =>
                Object.keys(translations).includes(code) ? (
                  <li className="Flyout--item" key={`${code}`}>
                    <Link
                      to={translations[code] || '/'}
                      language={code}
                      className={classNames('Flyout--item--link', {
                        'is-active': locale === code,
                      })}
                    >
                      {name}
                    </Link>
                  </li>
                ) : null,
              )}
            </ul>
          </div>
        </div>
      </div>
    </li>
  ) : null;
};
