import classNames from 'classnames';
import React, { useState } from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';
import { MenuItemProps } from '../1-molecules';

export type MobileMenuProps = {
  items: MenuItemProps[];
  isRoot?: boolean;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({
  items,
  isRoot = false,
}) => {
  const { domain } = useDomain();
  const { Link } = useFrameworkDependencies();
  const initialMenuState = items.reduce((menuState, item) => {
    menuState[item.id] = false;
    return menuState;
  }, {} as Record<string, boolean>);
  const [menuState, setMenuState] =
    useState<Record<string, boolean>>(initialMenuState);

  return (
    <ul
      className={classNames('MobileMainNav--list', {
        'MobileMainNav--root': isRoot,
      })}
    >
      {items.map((item) => {
        const hasChildren =
          item.childItems?.length ||
          (item.attributes?.id &&
            ['group', 'aktuell'].includes(item.attributes?.id));

        return (
          <li
            key={item.id}
            className={classNames('MobileMainNav--item', {
              'is-opened': menuState[item.id],
              'has-childs': hasChildren,
              'is-group':
                item.attributes?.id === 'group' &&
                item.attributes.ariaControls === 'paraplegie--group' &&
                domain.sidebarMenu !== 'main',
              'MobileNav--cta MobileNav--cta-donation':
                item.attributes?.id === 'group-donate',
              'MobileNav--cta MobileNav--cta-membership':
                item.attributes?.id === 'group-member',
            })}
          >
            {hasChildren ? (
              <a
                href=""
                className={classNames('MobileMainNav--link', {
                  'is-opened': menuState[item.id],
                })}
                onClick={(event) => {
                  event.preventDefault();
                  setMenuState({
                    ...initialMenuState,
                    [item.id]: !menuState[item.id],
                  });
                }}
              >
                {item.title}
              </a>
            ) : (
              <Link to={item.url} className="MobileMainNav--link">
                {item.title}
              </Link>
            )}
            {item.childItems && <MobileMenu items={item.childItems} />}
          </li>
        );
      })}
    </ul>
  );
};
