import { Locale } from '../../i18n';
import { useFrameworkDependencies } from '../dependencies';
import { isTruthy } from '../utils/isTruthy';
import { useDomain } from './useDomain';
import { useOverlaysQuery } from './useOverlaysQuery';

export const useOverlays = (locale?: Locale) => {
  const { domain } = useDomain();
  const { useLocalization } = useFrameworkDependencies();
  const { locale: currentLocale } = useLocalization();
  const overlays = useOverlaysQuery();

  return overlays.fetchAllOverlays
    ?.filter(
      (overlay) =>
        overlay?.domainAccess?.some(
          (overlayDomain) => overlayDomain?.id === domain.id,
        ) && overlay?.locale === (locale || currentLocale),
    )
    .filter(isTruthy);
};
