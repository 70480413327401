import classNames from 'classnames';
import { decode } from 'he';
import React, { ReactNode } from 'react';

import { FundingWidget, FundingWidgetProps } from './FundingWidget';
import { MembershipWidget } from './MembershipWidget';

export type HeaderHeroProps = {
  title?: string;
  subtitle?: string;
  image?: string | ReactNode;
  fundingWidget?: Pick<FundingWidgetProps, 'amounts' | 'paymentUrl'>;
  showMembershipWidget?: boolean;
  showGiftMembershipWidget?: boolean;
  showDonationWidget?: boolean;
  className?: string;
};

export const HeaderHero: React.FC<HeaderHeroProps> = ({
  title,
  subtitle,
  image,
  fundingWidget,
  showMembershipWidget,
  showGiftMembershipWidget,
  showDonationWidget,
  className,
}) => {
  return (
    <div
      className={classNames('HeaderHero--wrapper', {
        'has-widget':
          (showDonationWidget && !!fundingWidget) ||
          showMembershipWidget ||
          showGiftMembershipWidget,
      })}
    >
      <div
        className={classNames('HeaderHero', className, {
          'has-widget':
            (showDonationWidget && !!fundingWidget) ||
            showMembershipWidget ||
            showGiftMembershipWidget,
        })}
      >
        <div className="HeaderHero--inner">
          {image && (
            <div
              className={classNames('HeaderHero--background is-initialized', {
                'is-widget': showDonationWidget && !!fundingWidget,
              })}
            >
              {typeof image === 'string' ? (
                <img
                  src={image}
                  className="HeaderHero--background--img"
                  alt={title}
                />
              ) : (
                image
              )}
            </div>
          )}
          <div className="HeaderHero--body">
            <div className="HeaderHero--text">
              {title && (
                <h1
                  className="HeaderHero--title"
                  dangerouslySetInnerHTML={{ __html: decode(title) }}
                />
              )}
              {subtitle && (
                <div
                  className="HeaderHero--paragraph"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {(showDonationWidget ||
        showMembershipWidget ||
        showGiftMembershipWidget) && (
        <div className="HeaderHero--widgets HeaderHero--widgets--FormCard">
          {showDonationWidget && !!fundingWidget && (
            <div className="HeaderHero--widget HeaderHero--widget--FormCard HeaderHero--widget--FundingWidget">
              <FundingWidget
                amounts={fundingWidget.amounts}
                paymentUrl={fundingWidget.paymentUrl}
                inHeader={true}
              />
            </div>
          )}
          {(showMembershipWidget || showGiftMembershipWidget) && (
            <div className="HeaderHero--widget HeaderHero--widget--FormCard HeaderHero--widget--FundingWidget">
              <MembershipWidget
                inHeader={true}
                isGift={showGiftMembershipWidget}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
