import { Locale } from '../../i18n';
import { Domain } from '../types';

export const dynamicUrl = (
  url: string,
  attrib: string | undefined,
  domain: Domain | undefined,
  language: Locale,
): string => {
  let urlToSwitch = url;
  if (attrib === 'contact') {
    switch (domain?.id) {
      case 'paraplegie_ch':
      case 'stiftung_paraplegie_ch':
      case 'gonner_vereinigung_paraplegie_ch':
        switch (language) {
          case 'de':
            urlToSwitch = '/de/wichtige-kontakte/';
            break;
          case 'en':
            urlToSwitch = '/en/important-contacts/';
            break;
          case 'fr':
            urlToSwitch = '/fr/principaux-contacts/';
            break;
          case 'it':
            urlToSwitch = '/it/contatti-importanti/';
            break;
        }
        break;
      case 'zentrum_paraplegie_ch':
        switch (language) {
          case 'de':
            urlToSwitch = '/spz/de/kontakt-spz/';
            break;
          case 'en':
            urlToSwitch = '/spz/en/contact-spc/';
            break;
          case 'fr':
            urlToSwitch = '/spz/fr/contact-csp/';
            break;
          case 'it':
            urlToSwitch = '/spz/it/contatto-csp/';
            break;
        }
        break;
      case 'parahelp_paraplegie_ch':
        switch (language) {
          case 'de':
            urlToSwitch = '/parahelp/de/kontakt-parahelp/';
            break;
          case 'en':
            urlToSwitch = '/parahelp/de/kontakt-parahelp/';
            break;
          case 'fr':
            urlToSwitch = '/parahelp/fr/contact-parahelp/';
            break;
          case 'it':
            urlToSwitch = '/parahelp/it/contatto-parahelp/';
            break;
        }
        break;
      case 'forschung_paraplegie_ch':
        switch (language) {
          case 'de':
            urlToSwitch = '/spf/de/kontakt-spf/';
            break;
          case 'en':
            urlToSwitch = '/spf/en/contact-spf/';
            break;
          case 'fr':
            urlToSwitch = '/spf/fr/contact-rsp/';
            break;
          case 'it':
            urlToSwitch = '/spf/it/contatto-rsp/';
            break;
        }
        break;
      case 'sirmed_paraplegie_ch':
        switch (language) {
          case 'de':
            urlToSwitch = '/sirmed/de/ueber-uns/kontakt/wo-sie-uns-finden/';
            break;
          case 'en':
            urlToSwitch = '/sirmed/en/about-us/kontakt/where-find-us-0/';
            break;
          case 'fr':
            urlToSwitch = '/sirmed/fr/portrait/contact/ou-nous-trouver-0/';
            break;
          case 'it':
            urlToSwitch = '/sirmed/it/chi-siamo/contatto/dove-trovarci/';
            break;
        }
        break;
      case 'orthotec_paraplegie_ch':
        switch (language) {
          case 'de':
            urlToSwitch =
              '/orthotec/de/ueber-uns/kontaktieren-sie-uns/wo-sie-uns-finden/';
            break;
          case 'en':
            urlToSwitch = '/orthotec/en/about-us/contact-us/where-find-us/';
            break;
          case 'fr':
            urlToSwitch =
              '/orthotec/fr/notre-portrait/sites-et-heures-douverture/ou-nous-trouver/';
            break;
          case 'it':
            urlToSwitch =
              '/orthotec/it/chi-siamo/sedi-e-orari-di-apertura/dove-trovarci/';
            break;
        }
        break;
      case 'activecommunication_paraplegie_ch':
        switch (language) {
          case 'de':
            urlToSwitch = '/activecommunication/de/kontakt-ac/';
            break;
          case 'en':
            urlToSwitch = '/activecommunication/de/kontakt-ac/';
            break;
          case 'fr':
            urlToSwitch = '/activecommunication/fr/contact-ac/';
            break;
          case 'it':
            urlToSwitch = '/activecommunication/de/kontakt-ac/';
            break;
        }
        break;
    }
  }
  return urlToSwitch;
};
