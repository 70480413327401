import classNames from 'classnames';
import React from 'react';

import { useMobileNavigation } from '../../hooks';

export type MobileNavigationToggleProps = {
  title: string;
};

export const MobileNavigationToggle: React.FC<MobileNavigationToggleProps> = ({
  title,
}) => {
  const { isMobileNavigationVisible, toggleMobileNavigation } =
    useMobileNavigation();

  return (
    <button
      aria-expanded="false"
      aria-haspopup="true"
      type="button"
      className={classNames('MobileNav--toggle MobilNavToggles--button', {
        'is-active': isMobileNavigationVisible,
      })}
      onClick={toggleMobileNavigation}
    >
      <span className="visuallyhidden">{title}</span>
      <span className="MobileNavToggle--burger">
        <span className="MobileNavToggle--burger--line" />
        <span className="MobileNavToggle--burger--line" />
        <span className="MobileNavToggle--burger--line" />
      </span>
    </button>
  );
};
