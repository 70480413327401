import React from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from '../../../i18n';
import { homepagePaths } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';
import { getLocalizedUrl } from '../../utils';

export const MobileLanguageSwitcher: React.FC = () => {
  const { useLocalization, navigate, usePageTranslations } =
    useFrameworkDependencies();
  const { domain } = useDomain();
  const { locale, locales } = useLocalization();
  const translationsMap = usePageTranslations();
  const translations = Object.fromEntries(
    translationsMap as Map<Locale, string>,
  );
  const { t } = useTranslation();

  return Object.keys(translations).length ? (
    <form className="MobileMetaNav--language">
      <label className="MobileMetaNav--language--label" htmlFor="language">
        {t('Language')}
        <div className="MobileMetaNav--language--select">
          <select
            className="MobileMetaNav--language--select"
            name="language"
            defaultValue={locale}
            onChange={(e) =>
              navigate(
                getLocalizedUrl({
                  to: homepagePaths.includes(
                    translations[e.target.value as Locale] || '',
                  )
                    ? '/'
                    : translations[e.target.value as Locale] || '',
                  locale: e.target.value as Locale,
                  domain,
                }).url,
              )
            }
          >
            {Object.values(locales).map(({ code, name }) =>
              Object.keys(translations).includes(code) ? (
                <option key={code} value={code}>
                  {name}
                </option>
              ) : null,
            )}
          </select>
        </div>
      </label>
    </form>
  ) : null;
};
