import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import {
  useDomain,
  useFlyout,
  useMenu,
  useMobileNavigation,
  useSiteSearch,
} from '../../hooks';
import { useCallToAction } from '../../utils';
import { MobileNavigationToggle, SecondaryTitle } from '../0-atoms';
import { FastNavigationMenu } from './FastNavigationMenu';
import { HeaderFlyoutOrganisation } from './HeaderFlyoutOrganisation';
import { Logo } from './Logo';
import { MainMenu } from './MainMenu';
import { MetaNavigationMenu } from './MetaNavigationMenu';
import { MobileNavigation } from './MobileNavigation';
import { SearchForm } from './SearchForm';

export type HeaderProps = {
  hideHeader?: boolean;
  hideCtasHeader?: boolean;
};

export const Header: React.FC<HeaderProps> = ({
  hideHeader = false,
  hideCtasHeader = false,
}) => {
  const { domain } = useDomain();
  const { activeFlyout, hideFlyout } = useFlyout();
  const { t } = useTranslation();
  const { isSiteSearchVisible, hideSearch } = useSiteSearch();
  const { isMobileNavigationVisible, hideMobileNavigation } =
    useMobileNavigation();
  const { Link } = useFrameworkDependencies();
  const mainMenuItems = useMenu(domain.sidebarMenu || 'main');
  const metaNavigationMenuItems = useMenu(
    domain.metaNavigationMenu || 'meta-navigation',
  );
  const fastNavigationMenuItems = useMenu('fast-navigation-menu');
  const primaryCallToAction = useCallToAction('primary');

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 20);
  }, [isLoaded]);

  return (
    <header
      className={classNames('SiteHeader', {
        'no-metanav': hideHeader,
        'is-search-visible': isSiteSearchVisible,
      })}
    >
      <SecondaryTitle className="visuallyhidden">
        {t('Navigation area')}
      </SecondaryTitle>
      <FastNavigationMenu
        title="Fast Navigation Menu"
        items={fastNavigationMenuItems}
      />
      <div
        className={classNames('SiteHeader--overlay', {
          'is-visible':
            isSiteSearchVisible || isMobileNavigationVisible || !!activeFlyout,
        })}
        onClick={() => {
          hideFlyout();
          hideSearch();
          hideMobileNavigation();
        }}
      />
      <div className="SiteHeader--spacer" />
      <div className="SiteHeader--sticky">
        <div className="SiteHeader--inner">
          {/* Show for PAR groups desktop and all mobi including the blue PAR main logo. */}
          <div className="SiteHeader--logo">
            {isLoaded && (
              <>
                <Logo isDesktop />
                <Logo />
              </>
            )}
          </div>

          {hideHeader && (
            <div className="SiteHeader--nav is-hideHeader" id="site-navigation">
              <div className="SiteHeader--navContainer">
                <div className="SiteHeader--mainNavWrapper">
                  <div className="SiteHeader--logo-par">
                    <Logo isDesktop isWhiteLogo />
                  </div>
                </div>
              </div>
            </div>
          )}

          {!hideHeader && (
            <div className="SiteHeader--nav" id="site-navigation">
              <div className="SiteHeader--navContainer">
                <div className="SiteHeader--metaNavWrapper">
                  <MetaNavigationMenu
                    title="Meta navigation menu"
                    items={metaNavigationMenuItems}
                  />
                </div>
                <div className="SiteHeader--mainNavWrapper">
                  {domain.id === 'paraplegie_ch' && (
                    <div className="SiteHeader--logo-par">
                      {activeFlyout ? (
                        <Logo isDesktop noPadding />
                      ) : (
                        <Logo isDesktop isWhiteLogo />
                      )}
                    </div>
                  )}
                  <MainMenu
                    title="Main Menu"
                    items={mainMenuItems}
                    hideCtasHeader={hideCtasHeader}
                  />
                </div>
                <div className="SiteHeader--navIndicator">
                  <div className="SiteHeader--navIndicator--indicationBar" />
                </div>
              </div>
              <div className="SiteHeader--mobileNav">
                <div className="MobileNavToggles">
                  {domain.id === 'paraplegie_ch' &&
                    !hideCtasHeader &&
                    primaryCallToAction && (
                      <Link
                        className="Button icon-before cta-red"
                        to={primaryCallToAction.url}
                      >
                        <i
                          className={classNames(
                            'before',
                            'ico',
                            'inline',
                            `ico-${primaryCallToAction.type}-white`,
                          )}
                        />
                        <span className="Button--text">
                          {t(`cta.button_type.${primaryCallToAction.type}`)}
                        </span>
                      </Link>
                    )}
                  <MobileNavigationToggle title={t('Open navigation')} />
                </div>
              </div>
            </div>
          )}
          <div className="SiteHeader--searchContainer">
            {!hideHeader && <SearchForm />}
          </div>
        </div>
        <div className="SiteHeader--flyoutContainer">
          <HeaderFlyoutOrganisation />
        </div>
      </div>
      {!hideHeader && <MobileNavigation hideCtasHeader={hideCtasHeader} />}
    </header>
  );
};
