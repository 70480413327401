import classNames from 'classnames';
import React from 'react';

import { useFrameworkDependencies } from '../../dependencies';
import { dynamicUrl, useFlyout } from '../../hooks';
import { Domain, MenuItem as MenuItemType } from '../../types';
import { buildUrl } from '../../utils';

export type MenuItemProps = MenuItemType & {
  childMenuAttributes?: React.HTMLAttributes<HTMLUListElement>;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
  className?: string;
  localDomain?: Domain;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  url,
  title,
  attributes,
  childItems,
  childMenuAttributes,
  onClick,
  className,
  children,
  localDomain,
}) => {
  const { Link, useLocation, useLocalization } = useFrameworkDependencies();
  const { activeFlyout, toggleFlyout } = useFlyout();
  const { pathname } = useLocation();
  const { locale } = useLocalization();

  const isCurrent = url && buildUrl([pathname]).match(buildUrl([`/${url}`]));

  return attributes?.enabled ? (
    <li
      className={classNames(className, {
        'is-active': attributes?.ariaControls === activeFlyout,
        'is-current': isCurrent,
      })}
    >
      <Link
        to={dynamicUrl(url, attributes?.id, localDomain, locale)}
        title={attributes?.title}
        aria-haspopup
        aria-controls={attributes?.ariaControls}
        aria-expanded={activeFlyout === attributes?.ariaExpanded}
        accessKey={attributes?.accessKey}
        target={attributes?.target}
        id={attributes?.id}
        className={classNames(attributes?.class, {
          'has-childs': childItems?.length,
          'is-active': attributes?.ariaControls === activeFlyout,
          'is-current': isCurrent,
        })}
        onClick={(event) => {
          if (attributes?.ariaControls) {
            toggleFlyout(attributes?.ariaControls);
            event.preventDefault();
            event.stopPropagation();
          }
          onClick && onClick(event);
        }}
      >
        {children ? children : <span>{title}</span>}
      </Link>
      {childItems && childItems.length > 0 && (
        <ul {...childMenuAttributes}>
          {childItems.map((childItem) => (
            <MenuItem
              key={`${childItem.id}`}
              childMenuAttributes={childMenuAttributes}
              className={className}
              {...childItem}
            />
          ))}
        </ul>
      )}
    </li>
  ) : null;
};
