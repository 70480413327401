import classNames from 'classnames';
import Headroom from 'headroom.js';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';
import { BreadcrumbLink } from '../../types';
import { useCallToAction } from '../../utils';
import { ScrollToTop } from '../0-atoms';
import { Breadcrumbs } from './Breadcrumbs';

export type HeaderStickyProps = {
  breadcrumbs?: BreadcrumbLink[];
  hideHeader?: boolean;
  hideCtasHeader?: boolean;
};

export const HeaderSticky: React.FC<HeaderStickyProps> = ({
  breadcrumbs = [],
  hideHeader = false,
  hideCtasHeader = false,
}) => {
  const { domain } = useDomain();
  const { t } = useTranslation();
  const headerRef = useRef<HTMLDivElement>(null);
  const readIndicatorRef = useRef<HTMLDivElement>(null);
  const { Link } = useFrameworkDependencies();
  const primaryCallToAction = useCallToAction('primary');
  const secondaryCallToAction = useCallToAction('secondary');

  const updateReadIndicator = debounce(
    () => {
      if (!readIndicatorRef.current) {
        return;
      }

      const currentState =
        document.body.scrollTop || document.documentElement.scrollTop;
      const pageHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      readIndicatorRef.current.style.width = `${Math.ceil(
        (currentState / pageHeight) * 100,
      )}%`;
    },
    50,
    {
      leading: true,
      trailing: true,
    },
  );

  useEffect(() => {
    if (!headerRef.current) {
      return;
    }
    const headroom = new Headroom(headerRef.current!, {
      offset: 125,
      tolerance: {
        up: 10,
        down: 0,
      },
      classes: {
        pinned: 'is-pinned',
        unpinned: 'is-unpinned',
        top: 'is-top',
        notTop: 'is-notTop',
        bottom: 'is-bottom',
        notBottom: 'is-notBottom',
      },
      onPin: function () {
        document.documentElement.classList.remove('is-SiteHeader-unpinned');
        document.documentElement.classList.add('is-SiteHeader-pinned');
      },
      onUnpin: function () {
        document.documentElement.classList.remove('is-SiteHeader-pinned');
        document.documentElement.classList.add('is-SiteHeader-unpinned');
      },
      onTop: function () {
        document.documentElement.classList.remove('is-SiteHeader-notTop');
        document.documentElement.classList.add('is-SiteHeader-top');
      },
      onNotTop: function () {
        document.documentElement.classList.remove('is-SiteHeader-top');
        document.documentElement.classList.add('is-SiteHeader-notTop');
      },
    });

    headroom.init();

    return () => {
      try {
        headroom.destroy();
      } catch (e) {
        console.error('Error while trying to destroy Headroom');
        console.error(e);
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', updateReadIndicator);
    return () => window.removeEventListener('scroll', updateReadIndicator);
  });

  return (
    <div>
      <div className="HeaderSticky" ref={headerRef}>
        <div className="HeaderSticky--stickybox">
          <div className="HeaderSticky--inner">
            <div className="HeaderSticky--left">
              <ScrollToTop title={t('Zum Anfang der Seite')} />
              {breadcrumbs && breadcrumbs.length > 0 && (
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              )}
            </div>
            {domain.id === 'paraplegie_ch' && !hideHeader && !hideCtasHeader && (
              <div className="HeaderSticky--right">
                {secondaryCallToAction && (
                  <Link
                    className="Button icon-before cta cta-blue button--uppercase"
                    to={secondaryCallToAction.url}
                  >
                    <i
                      className={classNames(
                        'before',
                        'ico',
                        'inline',
                        `ico-${secondaryCallToAction.type}-white`,
                      )}
                    />
                    <span className="Button--text">
                      {t(`cta.button_type.${secondaryCallToAction.type}`)}
                    </span>
                  </Link>
                )}
                {primaryCallToAction && (
                  <Link
                    className="Button icon-before cta HeaderSticky--primary-cta button--uppercase"
                    to={primaryCallToAction.url}
                  >
                    <i
                      className={classNames(
                        'before',
                        'ico',
                        'inline',
                        `ico-${primaryCallToAction.type}-white`,
                      )}
                    />
                    <span className="Button--text">
                      {t(`cta.button_type.${primaryCallToAction.type}`)}
                    </span>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="HeaderSticky--readindicator">
            <div
              ref={readIndicatorRef}
              className="HeaderSticky--readindicator--bar"
            />
          </div>
        </div>
      </div>
      {breadcrumbs && breadcrumbs.length > 0 && (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      )}
    </div>
  );
};
