export const setClosedOverlay = (overlayId: string) => {
  if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
    return;
  }
  const currentTimeSeconds = Math.floor(new Date().getTime() / 1000);
  const closedOverlays = JSON.parse(
    localStorage.getItem('closedOverlays') || '{}',
  );
  closedOverlays[overlayId] = {
    closedAt: currentTimeSeconds,
  };
  localStorage.setItem('closedOverlays', JSON.stringify(closedOverlays));
};

export const displayOverlay = (id: string, interval: number = 0) => {
  if (
    !id ||
    typeof window === 'undefined' ||
    typeof localStorage === 'undefined'
  ) {
    return false;
  }
  const closedOverlays = JSON.parse(
    localStorage.getItem('closedOverlays') || '{}',
  );
  const currentTimeSeconds = Math.floor(new Date().getTime() / 1000);
  const intervalInSeconds = interval * 3600;
  return (
    !closedOverlays[id] ||
    (intervalInSeconds > 0 &&
      currentTimeSeconds > intervalInSeconds + closedOverlays[id].closedAt)
  );
};
