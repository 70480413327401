import React, { ReactNode } from 'react';

import { setClosedOverlay } from '../../utils';
import { Teaser } from '../0-atoms';
import { ParagraphFloatingBox } from '../1-molecules';

export type OverlayFloatingBoxProps = {
  id: string;
  title: string;
  subtitle?: string;
  text?: string;
  image?: string | ReactNode;
  url?: string;
  linkLabel?: string;
  className?: string;
};

export const OverlayFloatingBox: React.FC<OverlayFloatingBoxProps> = ({
  id,
  title,
  subtitle,
  text,
  image,
  url,
  linkLabel,
  className,
}) => (
  <ParagraphFloatingBox className={className} image={image} imageFirst>
    <Teaser
      url={url}
      title={title}
      linkLabel={linkLabel}
      text={`${title}<br /><em>${subtitle}</em><br /><br/><em>${text?.replace(
        /(?:\\r\\n|\\r|\\n)/g,
        '</em><br />',
      )}`}
      onClick={() => setClosedOverlay(id)}
    />
  </ParagraphFloatingBox>
);
