export * from './useAccordion';
export * from './useBlogOrganisationTerms';
export * from './useBlogOrganisationTermsQuery';
export * from './useBlogPagination';
export * from './useBlogPaginationQuery';
export * from './useBreadcrumbs';
export * from './useCategoryTerms';
export * from './useCategoryTermsQuery';
export * from './useCompaniesFilters';
export * from './useCompaniesParaworkFilters';
export * from './useCountries';
export * from './useDoctorFunctionTerms';
export * from './useDoctorsFilters';
export * from './useDomain';
export * from './useEntityUrlsQuery';
export * from './useEventCategoryTerms';
export * from './useEventCategoryTermsQuery';
export * from './useEventsFilters';
export * from './useFieldOfExpertiseTerms';
export * from './useFieldOfExpertiseTermsQuery';
export * from './useFlyout';
export * from './useIndustryTerms';
export * from './useIndustryTermsQuery';
export * from './useParaworkJobTypeTerms';
export * from './useParaworkJobTypeTermsQuery';
export * from './useLatestEventsQuery';
export * from './useLocalization';
export * from './useMedicalDomainTerms';
export * from './useMedicalDomainTermsQuery';
export * from './useMedicalOffersFilters';
export * from './useMenu';
export * from './useMobileNavigation';
export * from './useMonthNames';
export * from './usePager';
export * from './usePageTranslations';
export * from './useScript';
export * from './useSearchResultsFilters';
export * from './useSearchResultsQuery';
export * from './useSiteMenusQuery';
export * from './useSiteMetadataQuery';
export * from './useSiteSearch';
export * from './useOverlays';
export * from './useOverlaysQuery';
export * from './useDynamicMenuUrl';
export * from './useNewsroomFilters';
export * from './useNewsTypesTerms';
export * from './useNewsTypesTermsQuery';

// @todo lazy queries empty array left for BC.
//   Check usages and remove.
export const queries = [];
