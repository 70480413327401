import React, { PropsWithChildren, useContext, useState } from 'react';

import { useFlyout } from './useFlyout';

export type SiteSearchContext = {
  isSiteSearchVisible: boolean;
  showSearch: () => void;
  hideSearch: () => void;
};
const SiteSearchContext = React.createContext<SiteSearchContext | undefined>(
  undefined,
);

export const SiteSearchProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { hideFlyout } = useFlyout();
  const [isSiteSearchVisible, setIsVisible] = useState<boolean>(false);
  const showSearch = () => {
    hideFlyout();
    setIsVisible(true);
  };
  const hideSearch = () => setIsVisible(false);

  return (
    <SiteSearchContext.Provider
      value={{
        isSiteSearchVisible,
        showSearch,
        hideSearch,
      }}
    >
      {children}
    </SiteSearchContext.Provider>
  );
};

export const useSiteSearch = () => {
  const context = useContext(SiteSearchContext);
  if (context === undefined) {
    throw new Error('useSiteSearch must be used within a SiteSearchProvider');
  }
  return context;
};
