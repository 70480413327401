import classNames from 'classnames';

//import { ClassValue } from 'classnames/types';
import { MenuItem } from '../types';

// @todo ClassValue instead of any when type import is fixed.
export const addClassToMenuItems =
  (...classes: any[]) =>
  (items: MenuItem[]): MenuItem[] =>
    items.map((menuItem) => ({
      ...menuItem,
      attributes: {
        ...menuItem.attributes,
        class: classNames(classes, menuItem.attributes?.class),
      },
      childItems: menuItem.childItems
        ? addClassToMenuItems(classes)(menuItem.childItems)
        : undefined,
    }));
