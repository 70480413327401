import React from 'react';
import { useTranslation } from 'react-i18next';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { DomainProvider, useDomain, useSiteSearch } from '../../hooks';
import { addClassToMenuItems } from '../../utils';
import { SecondaryTitle } from '../0-atoms';
import { MenuItem, MenuItemProps } from '../1-molecules';
import { LanguageSwitcher } from './LanguageSwitcher';

export type MetaNavigationMenuProps = {
  items: MenuItemProps[];
  title: string;
};

export const MetaNavigationMenu: React.FC<MetaNavigationMenuProps> = ({
  items,
  title,
}) => {
  const { Link, useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const { showSearch } = useSiteSearch();
  const { domain } = useDomain();

  return (
    <nav className="MetaNav">
      <SecondaryTitle className="visuallyhidden">{title}</SecondaryTitle>
      <div className="MetaNav--left">
        {domain.id === 'orthotecsports_paraplegie_ch' && (
          <Link
            className="MetaNav--link MetaNav--title no-par-mini-logo"
            to={APP_CONFIG.orthotecPagePath[locale]}
          >
            {t('orthotec_paraplegie_ch.title')}
          </Link>
        )}

        {domain.id !== 'paraplegie_ch' && (
          <span className="MetaNav--sub-text">{t('A part of')}</span>
        )}

        {domain.id === 'orthotecsports_paraplegie_ch' && (
          <Link
            className="MetaNav--link MetaNav--title par-mini-logo"
            to={APP_CONFIG.groupPagePath[locale]}
          >
            {t('Paraplegiker-Gruppe')}
          </Link>
        )}

        {domain.id !== 'orthotecsports_paraplegie_ch' && (
          <Link
            className="MetaNav--link MetaNav--title"
            to={APP_CONFIG.groupPagePath[locale]}
          >
            {t('Paraplegiker-Gruppe')}
          </Link>
        )}
        {domain.id !== 'orthotecsports_paraplegie_ch' && (
          <Link
            className="MetaNav--link MetaNav--title"
            to={APP_CONFIG.memberServicePagePath[locale]}
          >
            {t('Member Service')}
          </Link>
        )}
      </div>
      <div className="MetaNav--right">
        <ul className="MetaNav--list">
          {addClassToMenuItems('MetaNav--link')(items).map((menuItem) => {
            return menuItem.attributes?.id === 'search' ? (
              <MenuItem
                className="search"
                key={`${menuItem.id}`}
                {...menuItem}
                onClick={(event) => {
                  event.preventDefault();
                  showSearch();
                }}
              >
                <span className="visuallyhidden">${menuItem.title}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                >
                  <g fill="none" fillRule="evenodd">
                    <ellipse
                      cx="24.357"
                      cy="17.321"
                      stroke="currentColor"
                      rx="9"
                      ry="9.321"
                    />
                    <path
                      stroke="currentColor"
                      strokeLinecap="square"
                      d="M17.9285714,24.0714288 L8.26430961,33.7356906"
                    />
                  </g>
                </svg>
              </MenuItem>
            ) : (
              <DomainProvider domain="paraplegie_ch" key={`${menuItem.id}`}>
                <MenuItem {...menuItem} localDomain={domain} />
              </DomainProvider>
            );
          })}
          <LanguageSwitcher />
        </ul>
      </div>
    </nav>
  );
};
