import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain, useSiteSearch } from '../../hooks';
import { getLocalizedUrl } from '../../utils';

export type SearchFormProps = {
  className?: string;
};

export const SearchForm: React.FC<SearchFormProps> = ({ className }) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const { domain } = useDomain();
  const { isSiteSearchVisible, hideSearch } = useSiteSearch();

  return (
    <div
      className={classNames('SiteSearch', className, {
        'is-visible': isSiteSearchVisible,
      })}
    >
      <form
        action={
          getLocalizedUrl({
            to: '/search',
            locale,
            domain,
          }).url
        }
        className="SiteSearch--form"
        method="GET"
      >
        <label className="visuallyhidden" htmlFor="site-search">
          {t('Search term')}
        </label>
        <input
          className="SiteSearch--input"
          id="site-search"
          name="search"
          placeholder={t('Search on Paraplegie.ch')}
          type="text"
        />
        {domain.id !== 'paraplegie_ch' && (
          <input type="hidden" name="domain" value={domain.id} />
        )}
        <button className="SiteSearch--submit" type="submit">
          <span className="visuallyhidden">{t('Search')}</span>
        </button>
      </form>
      <button
        className="SiteSearch--close"
        type="button"
        onClick={() => hideSearch()}
      >
        <span className="visuallyhidden">{t('Close search')}</span>
        <i className="SiteSearch--close--icon" />
      </button>
    </div>
  );
};
