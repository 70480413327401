import { useTranslation } from 'react-i18next';

import { BreadcrumbLink } from '../types';
import { useDomain } from './useDomain';

type TransformedBreadcrumbLink = BreadcrumbLink & {
  isCurrent?: boolean;
  isBackLink?: boolean;
  isFirstItem?: boolean;
  isSecondItem?: boolean;
  hasEllipsis?: boolean;
};

export const useBreadcrumbs = (breadcrumbs: BreadcrumbLink[]) => {
  const { t } = useTranslation();
  const { domain } = useDomain();

  return breadcrumbs.reduce((acc, value, index, array) => {
    // If we have only one link, we will not display the breadcrumb. However
    // on mobile a link to the current domain would be very useful, since
    // we do not have any sidebar navigation there. So, in this case, we
    // just replace the link with a url to the current domain.
    if (array.length === 1) {
      return [
        ...acc,
        {
          ...value,
          text: t(domain.title),
          isFirstItem: true,
          isBackLink: true,
          isCurrent: false,
        },
      ];
    }
    if (array.length === 2 || array.length === 3) {
      return [
        ...acc,
        {
          ...value,
          isBackLink: index !== 0 && index !== array.length - 1,
          isCurrent: index === array.length - 1,
        },
      ];
    }
    if (array.length > 3) {
      return [
        ...acc,
        {
          ...value,
          hasEllipsis: index === 0 && true,
          isSecondItem: index === 1 && true,
          isBackLink: index === 0 && true,
          isCurrent: index === array.length - 1,
        },
      ];
    }
    return [
      ...acc,
      {
        ...value,
        isBackLink: index !== 0 && index !== array.length - 1,
        isCurrent: index === array.length - 1,
      },
    ];
  }, [] as TransformedBreadcrumbLink[]);
};
