import React from 'react';
import { useTranslation } from 'react-i18next';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { SecondaryTitle } from '../0-atoms';
import { MenuItemProps } from '../1-molecules';
import { MobileLanguageSwitcher } from './MobileLanguageSwitcher';

export type MobileMetaNavigationMenuProps = {
  items: MenuItemProps[];
  title: string;
};

export const MobileMetaNavigationMenu: React.FC<
  MobileMetaNavigationMenuProps
> = ({ items, title }) => {
  const { Link, useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();

  return (
    <div className="MobileMetaNav">
      <SecondaryTitle className="visuallyhidden">{title}</SecondaryTitle>

      <ul className="MobileMetaNav--list">
        {[
          {
            id: 'group-page',
            title: t('Paraplegiker-Gruppe'),
            url: APP_CONFIG.groupPagePath[locale],
          },
          {
            id: 'member-service-page',
            title: t('Member Service'),
            url: APP_CONFIG.memberServicePagePath[locale],
          },
          ...items,
        ]
          .filter(
            (item) =>
              !('attributes' in item) ||
              !item.attributes?.id ||
              item.attributes?.id !== 'search',
          )
          .map((item) => (
            <li key={item.id}>
              <Link
                to={item.url}
                className="MobileMetaNav--link"
                domain="paraplegie_ch"
              >
                {item.title}
              </Link>
            </li>
          ))}
        <li key="languageSwitcher">
          <MobileLanguageSwitcher />
        </li>
      </ul>
    </div>
  );
};
