import React from 'react';
import { useTranslation } from 'react-i18next';

import { MenuId } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useDomain, useMenu } from '../../hooks';
import { addClassToMenuItems } from '../../utils';
import {
  IconKey,
  IconSvg,
  NewsletterSignupButton,
  SecondaryTitle,
} from '../0-atoms';
import { MenuItem } from '../1-molecules';
import { Logo } from './Logo';

export const FooterOrganization: React.FC = () => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const { domain } = useDomain();
  const quickLinksItems = useMenu(domain.quickLinksMenu as MenuId);
  const newsletterUrl = domain.newsletterUrls?.[locale];
  const organisationFooterSocialMenuItems = useMenu(
    domain.organisationFooterSocialMenu as MenuId,
  );

  return (
    <div className="OrganisationFooter">
      <div className="OrganisationFooter--logo">
        <Logo isDesktop />
        <Logo />
      </div>
      <div className="OrganisationFooter--quick-links">
        <div className="OrganisationFooter--quick-links--title">
          {t('Quick Links')}
        </div>
        <div className="OrganisationFooter--quick-links--links">
          <SecondaryTitle className="OrganisationFooter--quick-links--title visuallyhidden">
            {t('Links')}
          </SecondaryTitle>
          <div className="quick-links">
            <ul className="quick-links--links">
              {addClassToMenuItems('QuickLinksList--link')(quickLinksItems).map(
                (menuItem) => (
                  <MenuItem key={`${menuItem.id}`} {...menuItem} />
                ),
              )}
            </ul>
          </div>
        </div>
        <div className="OrganisationFooter--newsletter">
          {newsletterUrl && (
            <NewsletterSignupButton
              label={t('Newsletter')}
              url={newsletterUrl}
            />
          )}
        </div>
      </div>
      <div className="OrganisationFooter--contact">
        <div className="OrganisationFooter--contact--title">{t('Contact')}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: t(`blocks.footer_contact.${domain.id}`),
          }}
        />
        {organisationFooterSocialMenuItems &&
          organisationFooterSocialMenuItems.length > 0 && (
            <div className="OrganisationFooter--social-media">
              <div className="OrganisationFooter--social-media--title">
                {t('{{domain}} {{subtext}}', {
                  domain: t(domain.title),
                  subtext: t('footer_organisation.socialMenuTitleSubtext'),
                })}
              </div>
              <div className="FooterSocialMedia">
                <ul className="FooterSocialMedia--list">
                  {organisationFooterSocialMenuItems
                    .map((menuItem) => ({
                      ...menuItem,
                      attributes: {
                        ...menuItem.attributes,
                        iconClass: menuItem.attributes?.class,
                        class: 'FooterSocialMedia--link',
                      },
                    }))
                    .map((menuItem) => (
                      <MenuItem key={`${menuItem.id}`} {...menuItem}>
                        <span className="visuallyhidden">{menuItem.title}</span>
                        {menuItem.attributes?.iconClass && (
                          <IconSvg
                            wrapClass={'FooterSocialMedia--link--icon'}
                            icon={menuItem.attributes?.iconClass as IconKey}
                            transition
                            fill="theme"
                          />
                        )}
                      </MenuItem>
                    ))}
                </ul>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
