import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDomain, useMenu, useMobileNavigation } from '../../hooks';
import { useCallToAction } from '../../utils';
import { SecondaryTitle } from '../0-atoms';
import { MobileMainMenu } from './MobileMainMenu';
import { MobileMenu } from './MobileMenu';
import { MobileMetaNavigationMenu } from './MobileMetaNavigationMenu';
import { SearchForm } from './SearchForm';

export type MobileNavigationProps = {
  className?: string;
  hideCtasHeader?: boolean;
};

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
  className,
  hideCtasHeader = false,
}) => {
  const { domain } = useDomain();
  const { t } = useTranslation();
  const { isMobileNavigationVisible } = useMobileNavigation();
  const mobileMainMenuItems = useMenu(domain.mainMenu || 'main');
  const mobileMetaNavigationMenuItems = useMenu(
    domain.metaNavigationMenu || 'meta-navigation',
  );
  const mobileSidebarMenuItems = useMenu(domain.sidebarMenu || 'main');
  const primaryCallToAction = useCallToAction('primary');
  const secondaryCallToAction = useCallToAction('secondary');

  return (
    <div
      className={classNames('MobileNav', className, {
        'is-active': isMobileNavigationVisible,
      })}
    >
      <div>
        <div className="MobileNav--search">
          <SearchForm className="static" />
        </div>
        {domain.id === 'paraplegie_ch' && !hideCtasHeader && (
          <div className="MobileNav--ctas">
            {secondaryCallToAction && (
              <button className="Button icon-before cta-white" type="button">
                <a type="button" href={secondaryCallToAction.url}>
                  <i
                    className={classNames(
                      'before',
                      'ico',
                      'inline',
                      `ico-${secondaryCallToAction.type}-red`,
                    )}
                  />
                  <span className="Button--text">
                    {t(`cta.button_type.${secondaryCallToAction.type}`)}
                  </span>
                </a>
              </button>
            )}
            {primaryCallToAction && (
              <button className="Button icon-before cta-red" type="button">
                <a type="button" href={primaryCallToAction.url}>
                  <i
                    className={classNames(
                      'before',
                      'ico',
                      'inline',
                      `ico-${primaryCallToAction.type}-white`,
                    )}
                  />
                  <span className="Button--text">
                    {t(`cta.button_type.${primaryCallToAction.type}`)}
                  </span>
                </a>
              </button>
            )}
          </div>
        )}
        {!domain.hideSidebarOnMobile && (
          <div className="MobileNav--menu">
            <div className="MobileMainNav themed">
              <SecondaryTitle className="visuallyhidden">{`${t(
                domain.name,
              )} Navigation`}</SecondaryTitle>
              <MobileMenu items={mobileSidebarMenuItems} />
            </div>
          </div>
        )}
        <div
          className={classNames('MobileNav--menu', {
            'is-active': domain.hideSidebarOnMobile,
          })}
        >
          <div className="MobileMainNav">
            <span className="visuallyhidden">{t('Main navigation')}</span>
            <MobileMainMenu items={mobileMainMenuItems} />
          </div>
          <MobileMetaNavigationMenu
            title={t('Meta navigation')}
            items={mobileMetaNavigationMenuItems}
          />
        </div>
      </div>
    </div>
  );
};
