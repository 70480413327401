// TODO: does this still apply?
// const activeStateMap = {
//   NodeBlog: 'blog',
//   NodeEvent: 'aktuell',
//   EventsList: 'aktuell',
//   NodeNews: 'aktuell',
//   NewsList: 'aktuell',
// };

import { Domain, MenuItem } from '../types';

export const transformMainMenuItems = (domain: Domain) => (items: MenuItem[]) =>
  items
    .map((menuItem) => ({
      // TODO: does this still apply?
      // active: activeStateMap[pageTypeId] && menuItem.htmlId === activeStateMap[pageTypeId],
      ...menuItem,
      childItems: undefined,
    }))
    .filter(
      (menuItem) =>
        domain.ignoreAriaControls ||
        (menuItem.attributes?.ariaControls &&
          menuItem.attributes?.ariaControls.length > 0),
    );
