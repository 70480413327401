import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { setClosedOverlay } from '../../utils';

export type OverlayFullProps = {
  id: string;
  title: string;
  subtitle?: string;
  text?: string;
  image?: string | ReactNode;
  url?: string;
  linkLabel?: string;
  className?: string;
};

export const OverlayFull: React.FC<OverlayFullProps> = ({
  id,
  title,
  text,
  image,
  url,
  linkLabel,
  className,
}) => {
  const { Link } = useFrameworkDependencies();
  const { t } = useTranslation();

  return (
    <div className={classNames('FullscreenImageText', className)}>
      {image && (
        <div className="FullscreenImageText--imgContainer">
          {typeof image === 'string' ? (
            <img
              className="FullscreenImageText--img"
              alt={title}
              title={title}
              src={image}
              loading="lazy"
            />
          ) : (
            image
          )}
        </div>
      )}
      <div className="FullscreenImageText--contentContainer">
        <div className="FullscreenImageText--content">
          <h2 className="FullscreenImageText--title h2">{title}</h2>
          {text && (
            <div
              className="FullscreenImageText--text"
              dangerouslySetInnerHTML={{
                __html: text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
              }}
            />
          )}
        </div>
        <div className="FullscreenImageText--contentFooter">
          {url && (
            <Link
              className="Button icon-after"
              to={url}
              onClick={() => setClosedOverlay(id)}
            >
              <span className="Button--text">{linkLabel || t('More')}</span>
              <i className="absolute after ico-bolt-right-white icon" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
