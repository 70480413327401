import classNames from 'classnames';
import pipe from 'lodash/fp/pipe';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useDomain, useFlyout } from '../../hooks';
import { MenuItem as MenuItemType } from '../../types';
import {
  addClassToMenuItems,
  transformMainMenuItems,
  useCallToAction,
} from '../../utils';
import { MenuItem, MenuItemProps } from '../1-molecules';

export type MainMenuProps = {
  items: MenuItemProps[];
  title: string;
  hideCtasHeader?: boolean;
};

export const MainMenu: React.FC<MainMenuProps> = ({
  items,
  hideCtasHeader = false,
}) => {
  const { Link } = useFrameworkDependencies();
  const { domain } = useDomain();
  const { t } = useTranslation();
  const { activeFlyout } = useFlyout();
  const mainMenuItems = pipe(
    transformMainMenuItems(domain),
    addClassToMenuItems('MainNav--link', {
      clickable: domain.clickableMainMenu,
    }),
  )(items);
  const primaryCallToAction = useCallToAction('primary');
  const secondaryCallToAction = useCallToAction('secondary');

  return (
    <nav
      className={classNames('MainNav', {
        'is-flyoutOpened': !!activeFlyout,
      })}
    >
      <div className="MainNav--left">
        <ul className="MainNav--categories">
          {mainMenuItems.map((menuItem: MenuItemType) => (
            <MenuItem key={`menu-${menuItem.id}`} {...menuItem} />
          ))}
        </ul>
      </div>
      {domain.id === 'paraplegie_ch' && !hideCtasHeader && (
        <div className="MainNav--right">
          {secondaryCallToAction && (
            <Link
              className="Button icon-before cta-white secondary"
              type="button"
              to={secondaryCallToAction.url}
            >
              <i
                className={classNames(
                  'before',
                  'ico',
                  'inline',
                  'primary',
                  `ico-${secondaryCallToAction.type}-blue`,
                )}
              />
              <span className="Button--text">
                {t(`cta.button_type.${secondaryCallToAction.type}`)}
              </span>
            </Link>
          )}
          {primaryCallToAction && (
            <Link
              className="Button icon-before cta-red"
              type="button"
              to={primaryCallToAction.url}
            >
              <i
                className={classNames(
                  'before',
                  'ico',
                  'inline',
                  `ico-${primaryCallToAction.type}-white`,
                )}
              />
              <span className="Button--text">
                {t(`cta.button_type.${primaryCallToAction.type}`)}
              </span>
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};
