import { MetaTagFragment } from '@custom/schema';

import { APP_CONFIG } from '../../constants';
import { LayoutProps } from '../../layout/layout';
import { isTruthy } from '../../utils/isTruthy';

export const transformMetatagToLayoutProps = (
  metatags?: (MetaTagFragment | undefined)[],
): Pick<LayoutProps, 'title' | 'links' | 'meta'> => {
  if (!metatags) {
    return {};
  }
  const title = metatags
    .filter(isTruthy)
    .filter(
      ({ tag, attributes }) => tag === 'meta' && attributes?.name === 'title',
    )
    .pop();
  const links = metatags.filter(isTruthy).filter(({ tag }) => tag === 'link');
  const meta = metatags.filter(isTruthy).filter(({ tag }) => tag === 'meta');

  return {
    title: title?.attributes?.content,
    links: links.map(({ attributes }) => ({
      rel: attributes?.rel || '',
      href: attributes?.href
        ? // If we are proxying, just use the frontend base url,
          // no need to apply any domain logic here.
          attributes.href.replace('https://nginx:8080', APP_CONFIG.netlifyUrl)
        : '',
    })),
    meta: meta.map(({ attributes }) => ({
      name: attributes?.name || attributes?.property || '',
      content: attributes?.content
        ? // If we are proxying, just use the frontend base url,
          // no need to apply any domain logic here.
          attributes.content.replace(
            'https://nginx:8080',
            APP_CONFIG.netlifyUrl,
          )
        : '',
    })),
  };
};
