import React from 'react';

export type ScrollToTopProps = {
  title: string;
};

export const ScrollToTop: React.FC<ScrollToTopProps> = ({ title }) => (
  <button
    className="HeaderSticky--totop"
    title={title}
    type="button"
    onClick={() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }}
  >
    <span className="visuallyhidden">{title}</span>
  </button>
);
