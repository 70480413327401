import { GatsbyBrowser, WrapPageElementBrowserArgs } from 'gatsby';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';

import { i18n, Locale } from '../../i18n';
import { DataDependencyWrapper, GatsbyDependencyWrapper } from '../gatsby-api';
import {
  DomainProvider,
  LocalizationProvider,
  PageTranslationsProvider,
} from '../hooks';
import { PageContext } from '../types';

export const WrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}: WrapPageElementBrowserArgs<any, PageContext>) => {
  return (
    <LocalizationProvider
      defaultLocale="de"
      locale={props.pageContext.locale as Locale}
    >
      <PageTranslationsProvider
        path={props.path}
        language={props.pageContext.locale as Locale}
        localizations={
          props.pageContext.localizations as Array<{
            path: string;
            locale: Locale;
          }>
        }
      >
        <GatsbyDependencyWrapper>
          <DataDependencyWrapper>
            <ParallaxProvider>
              <DomainProvider
                domain={props.pageContext.domain?.id || 'paraplegie_ch'}
              >
                <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
              </DomainProvider>
            </ParallaxProvider>
          </DataDependencyWrapper>
        </GatsbyDependencyWrapper>
      </PageTranslationsProvider>
    </LocalizationProvider>
  );
};
