import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DOMAINS } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';
import { getLocalizedUrl } from '../../utils';

export type LogoProps = {
  isDesktop?: boolean;
  noPadding?: boolean;
  domainId?: string;
  isWhiteLogo?: boolean;
};

export const Logo: React.FC<LogoProps> = ({
  domainId = '',
  isDesktop = false,
  noPadding = false,
  isWhiteLogo = false,
}) => {
  const { Link, useLocalization } = useFrameworkDependencies();
  let { domain } = useDomain();
  // Domain override.
  if (domainId !== '') {
    domain = DOMAINS[domainId];
  }
  const { t } = useTranslation();
  const { locale } = useLocalization();
  const logoSvg = noPadding
    ? domain?.logoNoPaddingSvg?.[locale]
    : domain?.logosvg?.[locale];
  const logoWhite = isWhiteLogo ? domain?.groupSVGWhite : '';

  const homeLink = getLocalizedUrl({
    to: '/',
    locale,
    domain,
  }).url;

  return (
    <div
      className={classNames('SiteLogo-wrapper', {
        SiteLogo: isDesktop,
        'is-hidden-desktop': !isDesktop,
      })}
    >
      <Link
        to={homeLink}
        className={classNames('SiteLogo--link', {
          'is-hidden-tablet-portrait': isDesktop,
          'is-visible-tablet-portrait': !isDesktop,
        })}
        id="SiteLogo--link"
        title={t(domain.title)}
      >
        <img
          alt={t(domain.title)}
          className={classNames(['SiteLogo--img', domain?.id])}
          src={isWhiteLogo ? logoWhite : logoSvg}
        />
      </Link>
    </div>
  );
};
