import { useQuery } from 'react-query';

export type LazyQueryDefinition = (
  graphql: (query: string) => Promise<any>,
) => Promise<any>;

const lazyQueryHash = (query: LazyQueryDefinition) => {
  // @ts-ignore
  const str = query((r) => r) as unknown as string;
  let hash = 0,
    i,
    chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
};

export const lazyStoragePath = (query: LazyQueryDefinition) => {
  return `q${lazyQueryHash(query)}.json`;
};

export function useLazyQuery<T>(query: LazyQueryDefinition, fallback: T): T {
  const res = useQuery(lazyQueryHash(query), async () => {
    const path = lazyStoragePath(query);
    return (await fetch(`/${path}`)).json();
  });
  return res.data?.data || fallback;
}
