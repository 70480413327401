import React, { PropsWithChildren, useContext, useState } from 'react';

import { useFlyout } from './useFlyout';

export type MobileNavigationContext = {
  isMobileNavigationVisible: boolean;
  showMobileNavigation: () => void;
  hideMobileNavigation: () => void;
  toggleMobileNavigation: () => void;
};
const MobileNavigationContext = React.createContext<
  MobileNavigationContext | undefined
>(undefined);

export const MobileNavigationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { hideFlyout } = useFlyout();
  const [isMobileNavigationVisible, setIsVisible] = useState<boolean>(false);
  const showMobileNavigation = () => {
    document.documentElement.classList.add('MobileNav-is-active');
    hideFlyout();
    setIsVisible(true);
  };
  const hideMobileNavigation = () => {
    document.documentElement.classList.remove('MobileNav-is-active');
    setIsVisible(false);
  };
  const toggleMobileNavigation = () =>
    isMobileNavigationVisible ? hideMobileNavigation() : showMobileNavigation();

  return (
    <MobileNavigationContext.Provider
      value={{
        isMobileNavigationVisible,
        showMobileNavigation,
        hideMobileNavigation,
        toggleMobileNavigation,
      }}
    >
      {children}
    </MobileNavigationContext.Provider>
  );
};

export const useMobileNavigation = () => {
  const context = useContext(MobileNavigationContext);
  if (context === undefined) {
    throw new Error(
      'useMobileNavigation must be used within a MobileNavigationProvider',
    );
  }
  return context;
};
