import classNames from 'classnames';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import BodyClassName from 'react-body-classname';

import { useFrameworkDependencies } from '../dependencies';

export type FlyoutContext = {
  activeFlyout: string | undefined;
  showFlyout: (id: string) => void;
  hideFlyout: () => void;
  toggleFlyout: (id: string) => void;
};

const FlyoutContext = React.createContext<FlyoutContext>(undefined!);

export const FlyoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { useLocation } = useFrameworkDependencies();
  const location = useLocation();
  const [activeFlyout, setActiveFlyout] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    hideFlyout();
  }, [location]);

  const showFlyout = (id: string) => setActiveFlyout(id);
  const hideFlyout = () => setActiveFlyout(undefined);
  const toggleFlyout = (id: string) => {
    if (id === activeFlyout) {
      return setActiveFlyout(undefined);
    }
    setActiveFlyout(id);
  };

  return (
    <FlyoutContext.Provider
      value={{
        activeFlyout,
        showFlyout,
        hideFlyout,
        toggleFlyout,
      }}
    >
      <BodyClassName
        className={classNames({
          'is-flyoutOpened': !!activeFlyout,
        })}
      />
      {children}
    </FlyoutContext.Provider>
  );
};

export const useFlyout = () => {
  const context = useContext(FlyoutContext);
  if (context === undefined) {
    throw new Error('useFlyout must be used within a FlyoutProvider');
  }
  return context;
};
