import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { APP_CONFIG } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { useDomain } from '../../hooks';
import { getLocalizedUrl } from '../../utils';
import { MenuItemProps } from '../1-molecules';
import { MobileMenu } from './MobileMenu';

export type MobileMainMenuProps = {
  items: MenuItemProps[];
};

export const MobileMainMenu: React.FC<MobileMainMenuProps> = ({ items }) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const { domain } = useDomain();

  const transformMobileMainMenuItems = pipe(
    filter(
      (item: MenuItemProps) =>
        !item.attributes ||
        !item.attributes?.id ||
        item.attributes?.id !== 'search',
    ),
    map((item) => {
      switch (item.attributes?.id) {
        case 'aktuell':
          return {
            ...item,
            childItems: [
              {
                id: 'group-events',
                title: t('Events'),
                url: getLocalizedUrl({
                  to: '/events',
                  locale,
                }).url,
              },
            ],
          };
        default:
          return item;
      }
    }),
  );

  const mainMenuItems =
    domain.id === 'paraplegie_ch'
      ? transformMobileMainMenuItems(items)
      : [
          {
            id: 'group',
            title: t('Paraplegiker-Gruppe'),
            url: '#',
            attributes: {
              id: 'group',
              class: 'has-childs',
              accessKey: '',
              ariaControls: 'paraplegie--group',
            },
            childItems: [
              {
                id: 'group-donate',
                title: t('Donate'),
                url: APP_CONFIG.donatePagePath[locale],
                attributes: {
                  id: 'group-donate',
                  accessKey: '',
                  class:
                    'MobileMainNav--item MobileNav--cta MobileNav--cta-donation',
                },
              },
              {
                id: 'group-member',
                title: t('Become a member'),
                url: APP_CONFIG.memberPagePath[locale],
                attributes: {
                  id: 'group-member',
                  accessKey: '',
                },
              },
              ...transformMobileMainMenuItems(items),
            ],
          },
        ];

  return <MobileMenu isRoot items={mainMenuItems} />;
};
