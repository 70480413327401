import React from 'react';
import { useTranslation } from 'react-i18next';

import { MenuId } from '../../constants';
import { useFrameworkDependencies } from '../../dependencies';
import { DomainProvider, useDomain, useMenu } from '../../hooks';
import { addClassToMenuItems } from '../../utils';
import { SecondaryTitle } from '../0-atoms';
import { MenuItem } from '../1-molecules';
import { EmployerBranding } from './EmployerBranding';
import { FooterNewsletter } from './FooterNewsletter';
import { FooterOrganization } from './FooterOrganization';
import { Logo } from './Logo';

export type FooterProps = {
  hideEmployerBranding?: boolean;
};

export const Footer: React.FC<FooterProps> = ({
  hideEmployerBranding = false,
}) => {
  const { useLocalization } = useFrameworkDependencies();
  const { locale } = useLocalization();
  const { domain } = useDomain();
  const { t } = useTranslation();
  const footerMenuItems = useMenu(
    (domain.footerMenu as MenuId) || 'footer-menu',
  );
  const footerSocialMenuItems = useMenu(
    (domain.footerSocialMenu as MenuId) || 'footer-social-menu',
  );

  return (
    <div className="SiteFooter--wrapper">
      {domain.id !== 'paraplegie_ch' && <FooterOrganization />}
      {locale === 'de' && !hideEmployerBranding && <EmployerBranding />}
      <footer
        className="SiteFooter paraplegie_ch"
        itemType="http://schema.org/WPFooter"
      >
        <SecondaryTitle className="visuallyhidden">
          {t('Footer')}
        </SecondaryTitle>
        <div className="SiteFooter--logo">
          <Logo isDesktop domainId="paraplegie_ch" noPadding />
          <Logo domainId="paraplegie_ch" noPadding />
        </div>
        <div className="SiteFooter--links">
          <div className="SiteFooter--section">
            <SecondaryTitle className="visuallyhidden">
              {t('Links')}
            </SecondaryTitle>
            <div className="FooterLinkList">
              <ul className="FooterLinkList--list">
                {addClassToMenuItems('FooterLinkList--link')(
                  footerMenuItems,
                ).map((menuItem) => (
                  <DomainProvider domain="paraplegie_ch" key={`${menuItem.id}`}>
                    <MenuItem {...menuItem} localDomain={domain} />
                  </DomainProvider>
                ))}
              </ul>
            </div>
          </div>
          <div className="SiteFooter--newsletter">
            {locale !== 'en' && (
              <div className="SiteFooter--section">
                <div className="SiteFooter--section--title h2">
                  {t('Newsletter')}
                </div>
                <FooterNewsletter />
              </div>
            )}
          </div>
        </div>
        <div className="SiteFooter--social">
          {domain.id === 'paraplegie_ch' && (
            <div className="SiteFooter--social-contact">
              <div className="SiteFooter--section--title h2">
                {t('Contact')}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('blocks.footer_contact'),
                }}
              />
            </div>
          )}
          <div className="SiteFooter--section">
            <div className="SiteFooter--section--title h2">
              {t('footer.socialMenuTitle')}
            </div>
            <div className="FooterSocialMedia">
              <ul className="FooterSocialMedia--list">
                {footerSocialMenuItems
                  .map((menuItem) => ({
                    ...menuItem,
                    attributes: {
                      ...menuItem.attributes,
                      iconClass: menuItem.attributes?.class,
                      class: 'FooterSocialMedia--link',
                    },
                  }))
                  .map((menuItem) => (
                    <MenuItem key={`${menuItem.id}`} {...menuItem}>
                      <span className="visuallyhidden">{menuItem.title}</span>
                      {menuItem.attributes?.iconClass && (
                        <i className={menuItem.attributes.iconClass} />
                      )}
                    </MenuItem>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
