export type FixedMetatags = Record<
  string,
  {
    title?: string;
    links?: Array<{
      rel: string;
      href: string;
    }>;
    meta?: Array<{
      name: string;
      content: string;
    }>;
  }
>;

export const fixedMetatags: FixedMetatags = {
  '/en/blog/': {
    title: 'Blog | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/en/blog/',
      },
    ],
  },
  '/de/blog/': {
    title: 'Blog | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/de/blog/',
      },
    ],
  },
  '/fr/blog/': {
    title: 'Blog | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/fr/blog/',
      },
    ],
  },
  '/it/blog/': {
    title: 'Blog | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/it/blog/',
      },
    ],
  },
  '/en/blog/all/': {
    title: 'Blog | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/en/blog/',
      },
    ],
  },
  '/de/blog/all/': {
    title: 'Blog | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/de/blog/',
      },
    ],
  },
  '/fr/blog/all/': {
    title: 'Blog | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/fr/blog/',
      },
    ],
  },
  '/it/blog/all/': {
    title: 'Blog | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/it/blog/',
      },
    ],
  },
  '/en/events/': {
    title: 'Events | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/en/events/',
      },
    ],
  },
  '/de/events/': {
    title: 'Events | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/de/events/',
      },
    ],
  },
  '/fr/events/': {
    title: 'Events | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/fr/events/',
      },
    ],
  },
  '/it/events/': {
    title: 'Events | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/it/events/',
      },
    ],
  },
  '/en/events/all/': {
    title: 'Events | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/en/events/',
      },
    ],
  },
  '/de/events/all/': {
    title: 'Events | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/de/events/',
      },
    ],
  },
  '/fr/events/all/': {
    title: 'Events | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/fr/events/',
      },
    ],
  },
  '/it/events/all/': {
    title: 'Events | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/it/events/',
      },
    ],
  },
  '/en/history/': {
    title: 'History | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/en/history/',
      },
    ],
  },
  '/de/history/': {
    title: 'History | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/de/history/',
      },
    ],
  },
  '/fr/history/': {
    title: 'History | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/fr/history/',
      },
    ],
  },
  '/it/history/': {
    title: 'History | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/it/history/',
      },
    ],
  },
  '/en/news/': {
    title: 'News | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/en/news/',
      },
    ],
  },
  '/de/news/': {
    title: 'News | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/de/news/',
      },
    ],
  },
  '/fr/news/': {
    title: 'News | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/fr/news/',
      },
    ],
  },
  '/it/news/': {
    title: 'News | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/it/news/',
      },
    ],
  },
  '/en/search/': {
    title: 'Search | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/en/search/',
      },
    ],
  },
  '/de/search/': {
    title: 'Suche | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/de/search/',
      },
    ],
  },
  '/fr/search/': {
    title: 'Recherche | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/fr/search/',
      },
    ],
  },
  '/it/search/': {
    title: 'Cerca | Swiss Paraplegic Group',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/it/search/',
      },
    ],
  },
  '/sps/en/search/': {
    title: 'Search | Swiss Paraplegic Foundation',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sps/en/search/',
      },
    ],
  },
  '/spz/en/search/': {
    title: 'Search | Swiss Paraplegic Centre',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/spz/en/search/',
      },
    ],
  },
  '/gv/en/search/': {
    title: 'Search | Benefactors Association',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/gv/en/search/',
      },
    ],
  },
  '/spf/en/search/': {
    title: 'Search | Swiss Paraplegic Research',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/spf/en/search/',
      },
    ],
  },
  '/sportmedizin/en/search/': {
    title: 'Search | Sports Medicine Nottwil',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sportmedizin/en/search/',
      },
    ],
  },
  '/activecommunication/en/search/': {
    title: 'Search | Active Communication',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/activecommunication/en/search/',
      },
    ],
  },
  '/sirmed/en/search/': {
    title: 'Search | Sirmed',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sirmed/en/search/',
      },
    ],
  },
  '/parahelp/en/search/': {
    title: 'Search | ParaHelp',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/parahelp/en/search/',
      },
    ],
  },
  '/orthotec/en/search/': {
    title: 'Search | Orthotec',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/orthotec/en/search/',
      },
    ],
  },
  '/sps/de/search/': {
    title: 'Suche | Schweizer Paraplegiker-Stiftung',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sps/de/search/',
      },
    ],
  },
  '/spz/de/search/': {
    title: 'Suche | Schweizer Paraplegiker-Zentrum',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/spz/de/search/',
      },
    ],
  },
  '/gv/de/search/': {
    title: 'Suche | Gönner-Vereinigung',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/gv/de/search/',
      },
    ],
  },
  '/spf/de/search/': {
    title: 'Suche | Schweizer Paraplegiker-Forschung',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/spf/de/search/',
      },
    ],
  },
  '/sportmedizin/de/search/': {
    title: 'Suche | Sportmedizin Nottwil',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sportmedizin/de/search/',
      },
    ],
  },
  '/activecommunication/de/search/': {
    title: 'Suche | Active Communication',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/activecommunication/de/search/',
      },
    ],
  },
  '/sirmed/de/search/': {
    title: 'Suche | Sirmed',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sirmed/de/search/',
      },
    ],
  },
  '/parahelp/de/search/': {
    title: 'Suche | ParaHelp',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/parahelp/de/search/',
      },
    ],
  },
  '/orthotec/de/search/': {
    title: 'Suche | Orthotec',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/orthotec/de/search/',
      },
    ],
  },
  '/sps/fr/search/': {
    title: 'Recherche | Fondation suisse pour paraplégiques',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sps/fr/search/',
      },
    ],
  },
  '/spz/fr/search/': {
    title: 'Recherche | Centre suisse des paraplégiques',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/spz/fr/search/',
      },
    ],
  },
  '/gv/fr/search/': {
    title: 'Recherche | Association des bienfaiteurs',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/gv/fr/search/',
      },
    ],
  },
  '/spf/fr/search/': {
    title: 'Recherche | Recherche suisse pour paraplégiques',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/spf/fr/search/',
      },
    ],
  },
  '/sportmedizin/fr/search/': {
    title: 'Recherche | Médecine du sport Nottwil',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sportmedizin/fr/search/',
      },
    ],
  },
  '/activecommunication/fr/search/': {
    title: 'Recherche | Active Communication',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/activecommunication/fr/search/',
      },
    ],
  },
  '/sirmed/fr/search/': {
    title: 'Recherche | Sirmed',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sirmed/fr/search/',
      },
    ],
  },
  '/parahelp/fr/search/': {
    title: 'Recherche | ParaHelp',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/parahelp/fr/search/',
      },
    ],
  },
  '/orthotec/fr/search/': {
    title: 'Recherche | Orthotec',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/orthotec/fr/search/',
      },
    ],
  },
  '/sps/it/search/': {
    title: 'Cerca | Fondazione svizzera per paraplegici',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sps/it/search/',
      },
    ],
  },
  '/spz/it/search/': {
    title: 'Cerca | Centro svizzero per paraplegici',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/spz/it/search/',
      },
    ],
  },
  '/gv/it/search/': {
    title: 'Cerca | Unione dei sostenitori',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/gv/it/search/',
      },
    ],
  },
  '/spf/it/search/': {
    title: 'Cerca | Ricerca svizzera per paraplegici',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/spf/it/search/',
      },
    ],
  },
  '/sportmedizin/it/search/': {
    title: 'Cerca | Medicina dello Sport Nottwil',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sportmedizin/it/search/',
      },
    ],
  },
  '/activecommunication/it/search/': {
    title: 'Cerca | Active Communication',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/activecommunication/it/search/',
      },
    ],
  },
  '/sirmed/it/search/': {
    title: 'Cerca | Sirmed',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/sirmed/it/search/',
      },
    ],
  },
  '/parahelp/it/search/': {
    title: 'Cerca | ParaHelp',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/parahelp/it/search/',
      },
    ],
  },
  '/orthotec/it/search/': {
    title: 'Cerca | Orthotec',
    meta: [
      {
        name: 'referrer',
        content: 'origin',
      },
    ],
    links: [
      {
        rel: 'canonical',
        href: '/orthotec/it/search/',
      },
    ],
  },
};
