import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFrameworkDependencies } from '../../dependencies';
import { useBreadcrumbs } from '../../hooks';
import { BreadcrumbLink } from '../../types';

export type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbLink[];
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();
  const { Link } = useFrameworkDependencies();
  const transformedBreadcrumbs = useBreadcrumbs(breadcrumbs);
  const currentItem = transformedBreadcrumbs.find(
    (breadcrumb) => breadcrumb.isCurrent,
  );

  return (
    <nav
      className={classNames('Breadcrumbs', {
        'is-visible-tablet-portrait': breadcrumbs.length < 2,
        'show-all': showAll,
      })}
    >
      <span className="visuallyhidden">{t('You are here')}</span>
      <ol className="Breadcrumbs--list">
        {transformedBreadcrumbs.map(
          ({
            url,
            text,
            domain,
            isBackLink,
            isCurrent,
            hasEllipsis,
            isFirstItem,
            isSecondItem,
          }) => (
            <Fragment key={text.replace(/\s+/g, '-').toLowerCase()}>
              {isBackLink && !isCurrent && hasEllipsis && (
                <li
                  key="first--item"
                  className={classNames('Breadcrumbs--item', {
                    'is-visible': hasEllipsis,
                    'first-item': isFirstItem,
                    'is-toggle': isBackLink && !isCurrent,
                  })}
                >
                  <a
                    className={classNames('Breadcrumbs--link', {
                      'Breadcrumbs--toggle':
                        isBackLink && !isCurrent && hasEllipsis,
                    })}
                    href=""
                    onClick={(event) => {
                      event.preventDefault();
                      setShowAll(true);
                    }}
                  >
                    <span className="Breadcrumbs--link--text">...</span>
                  </a>
                </li>
              )}
              <li
                key={text}
                className={classNames('Breadcrumbs--item', {
                  'is-visible': !hasEllipsis,
                  'is-visible second-item':
                    !hasEllipsis && isBackLink && isSecondItem,
                  'is-backLink': isBackLink,
                  'is-current': isCurrent,
                })}
              >
                {isCurrent && (
                  <span className="visuallyhidden">{t('Is current page')}</span>
                )}
                <Link to={url} domain={domain} className="Breadcrumbs--link">
                  <span className="Breadcrumbs--link--text">{text}</span>
                </Link>
              </li>
            </Fragment>
          ),
        )}
      </ol>
      {currentItem && (
        <div className="Breadcrumbs--item is-visible is-current-desktop">
          <Link to={currentItem.url} className="Breadcrumbs--link">
            <span className="Breadcrumbs--link--text">{currentItem.text}</span>
          </Link>
        </div>
      )}
    </nav>
  );
};
