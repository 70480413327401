import React from 'react';
import { useTranslation } from 'react-i18next';

import { setClosedOverlay } from '../../utils';

export type OverlayCloseProps = {
  id: string;
  modal?: HTMLDivElement | null;
};

export const OverlayClose: React.FC<OverlayCloseProps> = ({ id, modal }) => {
  const { t } = useTranslation();

  return (
    <button
      className="Modal--close"
      type="button"
      onClick={() => {
        setClosedOverlay(id);
        modal?.remove();
      }}
    >
      <span className="visuallyhidden">{t('Close overlay')}</span>
      <div className="Modal--close--icon" />
    </button>
  );
};
