import classNames from 'classnames';
import React from 'react';

export type SecondaryTitleProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export const SecondaryTitle: React.FC<SecondaryTitleProps> = ({
  className,
  children,
  ...props
}) => (
  <div className={classNames('h3', className)} {...props}>
    {children}
  </div>
);
