import React from 'react';

import { SecondaryTitle } from '../0-atoms';
import { MenuItem, MenuItemProps } from '../1-molecules';

export type FastNavigationMenuProps = {
  items: MenuItemProps[];
  title: string;
};

export const FastNavigationMenu: React.FC<FastNavigationMenuProps> = ({
  items,
  title,
}) => (
  <nav aria-describedby="Skiplinks--h3" className="Skiplinks" id="Skiplinks">
    <SecondaryTitle
      title={title}
      className="visuallyhidden"
      id="Skiplinks--h3"
    />
    <ul>
      {items.map((menuItem) => (
        <MenuItem key={`${menuItem.id}`} {...menuItem} />
      ))}
    </ul>
  </nav>
);
