import React, { ReactNode } from 'react';

import { setClosedOverlay } from '../../utils';
import { BlockTeaser } from '../1-molecules';

export type OverlayTransparentProps = {
  id: string;
  title: string;
  subtitle?: string;
  text?: string;
  image?: string | ReactNode;
  url?: string;
  linkLabel?: string;
  className?: string;
};

export const OverlayTransparent: React.FC<OverlayTransparentProps> = ({
  id,
  title,
  subtitle,
  text,
  image,
  url,
  linkLabel,
  className,
}) => (
  <BlockTeaser
    className={className}
    side="right"
    title={title}
    subtitle={subtitle}
    description={text?.replace(/(?:\r\n|\r|\n)/g, '<br>')}
    url={url || ''}
    linkLabel={linkLabel}
    image={image}
    onClick={() => setClosedOverlay(id)}
  />
);
